import messaging from '@react-native-firebase/messaging';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert, Platform, Vibration } from 'react-native';
import PushNotificationIOS from "@react-native-community/push-notification-ios";
import {checkNotifications, requestNotifications} from 'react-native-permissions';
import { eventEmitter } from './events';

//  -------------- WEB SETUP --------------------------
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getToken } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDTTlcqgCzpQFunYppz5zWgRib1h_URUVY",
    authDomain: "kestuboua.firebaseapp.com",
    projectId: "kestuboua",
    storageBucket: "kestuboua.appspot.com",
    messagingSenderId: "660222387407",
    appId: "1:660222387407:web:2b1b3f209b8923c4b443ba",
    measurementId: "G-G0TZPJWFZJ"
};
//  -------------- WEB SETUP ENDS --------------------------

// Initialize Firebase Messaging once
let firebaseMessaging = null;
if (Platform.OS != 'web') {
    firebaseMessaging = messaging();
} else {
    // ------------- WEB SETUP --------------------------
    const app = initializeApp(firebaseConfig);
    firebaseMessaging = getMessaging(app);
    // ------------- WEB SETUP ENDS --------------------------
}

async function checkNotificationEnabled() {

    if (Platform.OS === 'web') {
        return Notification.permission === 'granted';
    } else {
        if (Platform.OS === 'ios') {
            return new Promise((resolve, reject) => {
                PushNotificationIOS.checkPermissions(permissions => {
                    resolve(permissions.alert);
                });
            });
        } else if (Platform.OS === 'android') {
            return new Promise((resolve, reject) => {
                checkNotifications().then(({status, settings}) => {
                    if (status === 'granted') {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        }
        return true;
    }
}

async function requestNotificationPermission() {
    if (Platform.OS === 'web') {
        return new Promise((resolve, reject) => {
            Notification.requestPermission().then((result) => {
                if (result === 'granted') {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    } else {
        if (Platform.OS === 'ios') {
            return new Promise(async (resolve, reject) => {
                const authorizationStatus = await firebaseMessaging.requestPermission();
                if (authorizationStatus) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        } else if (Platform.OS === 'android') {
            return new Promise((resolve, reject) => {
                requestNotifications().then(({status, settings}) => {
                    if (status === 'granted') {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        }
        return true;
    }
}

async function requestUserPermission() {
    let enabled = false;
    try {
        if(Platform.OS != 'web') {
            const authStatus = await firebaseMessaging.requestPermission();
            enabled =
                authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
                authStatus === messaging.AuthorizationStatus.PROVISIONAL;
        }
    } catch (error) {
        console.log("Error in requestUserPermission", error.message);
        enabled = false;
    }
    return enabled;
}

async function getFcmToken() {
    let fcmToken = null;
    try {
        fcmToken = await AsyncStorage.getItem('fcmToken');
    } catch (error) {
        fcmToken = null;
    }
    if (!fcmToken) {
        try {
            if (Platform.OS != 'web') {
                fcmToken = await firebaseMessaging.getToken();
                if (fcmToken) {
                    await AsyncStorage.setItem('fcmToken', fcmToken);
                }
            } else {
                // ----------------------- WEB SETUP --------------------------
                fcmToken = await getToken(firebaseMessaging, { vapidKey: 'BDW8dg-KfJP1EBNxLzXEb9EqhI_-T8BffWXJjwPLMg5afAp7SKrLO4SlTbBiMVbIPSHubbDQw3kiOGzkIv3G1Ls' });
                if (fcmToken) {
                    await AsyncStorage.setItem('fcmToken', fcmToken);
                }
                // ----------------------- WEB SETUP ENDS --------------------------
            }
        } catch (error) {
            console.log("Error Getting FCM Token", error.message);
            fcmToken = null;
        }
    }
    return fcmToken;
}

const notificationListener = () => {

    try {
        firebaseMessaging.onNotificationOpenedApp(remoteMessage => {
            console.log('Notification caused app to open from background state:');
            eventEmitter.emit('notificationClicked', remoteMessage);
        });


        firebaseMessaging
            .getInitialNotification()
            .then(remoteMessage => {
            if (remoteMessage) {
                console.log('Notification caused app to open from quit state:');
                eventEmitter.emit('notificationClicked', remoteMessage);
            }
        });

        firebaseMessaging.onMessage(async remoteMessage => {
            Alert.alert(
                remoteMessage.notification.title,
                remoteMessage.notification.body,
                [
                    {
                        text: "OK",
                        onPress: () => {
                            eventEmitter.emit('notificationClicked', remoteMessage);
                        }
                    }
                ],
                { cancelable: false }
            );
            if (remoteMessage?.data?.showNotificationDot) {
                let showNotificationDot = JSON.parse(remoteMessage?.data?.showNotificationDot)
                if (showNotificationDot?.orders) {
                    eventEmitter.emit('showNotificationDot', 'Orders');
                }
            }
        });

        firebaseMessaging.setBackgroundMessageHandler(async remoteMessage => {
            console.log('Message handled in the background!');
        });

    } catch (error) {
        console.log("Error in notification listener", error.message);
    }

}

export { checkNotificationEnabled, requestNotificationPermission, requestUserPermission, getFcmToken, notificationListener };