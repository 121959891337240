import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, StatusBar, RefreshControl, Platform} from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import BottomNavigationBar from '../components/BottomNavigationBar';
import config from '../config';
import orderGroupService from '../services/orderGroups';
import I18n from '../utils/i18n';
import OrderGroupDetailsCard from '../components/OrderGroupDetailsCard';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RefreshButton from '../components/RefreshButton';
import KeepAwake from 'react-native-keep-awake';
import { isWideMode } from '../utils/common';
import AlertPopUpView from '../components/AlertPopUpView';
import { openLink } from '../utils/browser';

class OrderGroupDetails extends React.Component {
  constructor(props) {
    super(props);

    this.order_group_id =  this.props?.route?.params?.orderGroupId || this.props?.orderGroupId;
    this.showFooter = (this.props?.route?.params?.showFooter == false || this.props?.showFooter == false) ? false : true;
    this.history = this.props?.route?.params?.history;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      orderGroup: {},
      payingNow: false,
      isModalVisible: false,
      history: this.props?.route?.params?.history,
      loading: false,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      role: config.roles.USER.key,
      order_group_id: this.order_group_id
    }
  }

  // async componentDidUpdate(nextProps, nextState) {
  //   nextState = {
  //       ...nextState,
  //       ...nextProps
  //   }

  //   if (nextState.orderGroup?._id && nextState.orderGroup?._id != this.state.order_group_id) {
  //     // await this.setState({order_group_id: nextState.orderGroup?._id});
  //     // this.componentDidMount(false);
  //   }
  // }

  async componentDidMount(show_loading = true) {

    this.setState({history: this.props?.route?.params?.history})

    let current_role = await AsyncStorage.getItem(config.roleKey);
    
    this.setState({
      role: current_role
    });

    this.getOrderGroup(show_loading);


    let isAlwaysOnDisplay = await AsyncStorage.getItem('isAlwaysOnDisplay');
    if (isAlwaysOnDisplay) {
      isAlwaysOnDisplay = JSON.parse(isAlwaysOnDisplay);
      if (isAlwaysOnDisplay) {
        if (Platform.OS != 'web') {
          KeepAwake.activate();
        }

        this.timer = setInterval(() => {
          if (!this.state.isModalVisible)
            this.getOrderGroup(false, (Platform.OS == 'ios' ? false : true));
        }, 1000 * config.orderRefreshInterval);

      } else {
        if (Platform.OS != 'web') {
          KeepAwake.deactivate();
        }
      }
    } else {
      this.timer = setInterval(() => {
        if (!this.state.isModalVisible)
          this.getOrderGroup(false, (Platform.OS == 'ios' ? false : true));
      }, 1000 * config.orderRefreshIntervalLong);
    }

  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  async getOrderGroup(show_loading=true, show_small_loading=true) {
    this.setState({
      loading: show_small_loading,
      isModalVisible: show_loading && true,
      modal: this.loading_modal_props
    });

    let orderGroupRes = await orderGroupService.get_order_group(this.state.order_group_id);

    if(orderGroupRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(orderGroupRes.error_code),
        }
      })
      this.props.navigation.navigate('OrderGroups');
      return;
    }

    this.setState({
      orderGroup: orderGroupRes.orderGroup,
      loading: false,
      isModalVisible: show_loading && false,
    });
  }

  async conf(msg) {
    return new Promise((resolve, reject) => {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'info',
          title: msg,
          okCallback: () => {
            resolve(true);
            this.setState({
              isModalVisible: false,
            });
          },
          okText: I18n.t('yes'),
          cancelCallback: () => {
            resolve(false);
            this.setState({
              isModalVisible: false
            })
          },
          cancelText: I18n.t('no')
        }
      });
    });
  }

  async updateOrderGroupStatus(msg, status) {
    let ok = await this.conf(msg);
    if (!ok) return;

    this.setState({
      isModalVisible: true,
      modal: this.loading_modal_props
    });

    let updateOrderGroupRes = await orderGroupService.update_order_group(this.order_group_id, {status: status});

    if(updateOrderGroupRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(updateOrderGroupRes.error_code),
        }
      })
      return;
    }

    this.setState({
      isModalVisible: false,
      modal: this.default_modal_props
    });

    this.componentDidMount(false);
  }

  async payNow(paymentType=config.paymentType.ONLINE.key) {

    let ok;
    if (paymentType == config.paymentType.ONLINE.key) {
      ok = this.state.role == config.roles.USER.key ? await this.conf(I18n.t('do_you_want_to_pay_now')) : await this.conf(I18n.t('do_you_want_to_receive_payment_online'));
    } else if (paymentType == config.paymentType.CASH.key) {
      ok = this.state.role == config.roles.USER.key ? await this.conf(I18n.t('do_you_want_to_pay_by_cash')) : await this.conf(I18n.t('do_you_want_to_receive_payment_by_cash'));
    } else if (paymentType == config.paymentType.CARD.key) {
      ok = this.state.role == config.roles.USER.key ? await this.conf(I18n.t('do_you_want_to_pay_by_card')) : await this.conf(I18n.t('do_you_want_to_receive_payment_by_card'));
    }
    
    if (!ok) return;

    this.setState({
      // isModalVisible: true,
      // modal: this.loading_modal_props
    });

    let payNowRes = await orderGroupService.pay_now(this.order_group_id, paymentType);

    if(payNowRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(payNowRes.error_code),
          okCallback: () => {
            this.componentDidMount(false);
            this.setState({
              isModalVisible: false,
            });
          }
        }
      })
      return;
    }

    if (paymentType == config.paymentType.ONLINE.key && !payNowRes.payrexxLink) {
      this.setState({
        loading: false,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'warning',
          title: I18n.t('warning'),
          text: I18n.t('unable_to_pay_now'),
          okText: I18n.t('ok'),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
            this.componentDidMount(false);
          }
        }
      });
    } else if (paymentType == config.paymentType.ONLINE.key && payNowRes.payrexxLink) {
      
      await this.setState({
        loading: false,
        // isModalVisible: false
      });

      let browser_res = await openLink(payNowRes.payrexxLink, 'orderGroup', 'paymentStatus', payNowRes?.orderGroup?._id);
      if (browser_res.error) {
        this.props.navigation.navigate('Payment', {
          payrexxLink: payNowRes.payrexxLink,
          orderGroupId: payNowRes?.orderGroup?._id,
          payrexxId: payNowRes?.orderGroup?.payrexxId,
          payrexxReferenceId: payNowRes?.orderGroup?.payrexxReferenceId,
          redirectTo: isWideMode() ? 'OrderGroups' : 'OrderGroupDetails',
        });
      } else {
        this.props.navigation.reset({
          index: 0,
          routes: [{
            name: isWideMode() ? 'OrderGroups' : 'OrderGroupDetails',
            params: { orderId: null, orderGroupId: payNowRes?.orderGroup?._id, payrexxId: payNowRes?.orderGroup?.payrexxId, payrexxReferenceId: payNowRes?.orderGroup?.payrexxReferenceId } 
          }],
        });
      }
    } else {
      this.componentDidMount(false);
    }
  }

  async cancelOrderGroup() {

    let ok = await this.conf(I18n.t('do_you_want_to_cancel_all_orders'));
    if (!ok) return;

    this.setState({
      isModalVisible: true,
      modal: this.loading_modal_props
    });

    let cancelOrderGroupRes = await orderGroupService.cancel_order_group(this.order_group_id);
    
    if(cancelOrderGroupRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(cancelOrderGroupRes.error_code),
          okCallback: () => {
            this.componentDidMount(false);
            this.setState({
              isModalVisible: false,
            });
          }
        }
      })
      return;
    }

    if (cancelOrderGroupRes.message) {
      this.setState({
        loading: false,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'success',
          title: I18n.t('success'),
          text: I18n.t(cancelOrderGroupRes.message_code),
          okText: I18n.t('ok'),
          okCallback: () => {
            this.componentDidMount(false);
            this.setState({
              isModalVisible: false
            });
          }
        }
      });
    } else {
      this.componentDidMount(false);
      this.setState({
        isModalVisible: false,
        modal: this.default_modal_props
      });
    }
  }

  // async orderGroupPending() {
  //   await this.updateOrderGroupStatus(I18n.t('do_you_want_to_make_order_group_pending'), config.orderGroupStatus.PENDING.key);
  // }

  // async confirmOrderGroup() {
  //   await this.updateOrderGroupStatus(I18n.t('do_you_want_to_confirm_order_group'), config.orderGroupStatus.CONFIRMED.key);
  // }
  
  // async processOrderGroup() {
  //   await this.updateOrderGroupStatus(I18n.t('do_you_want_to_process_order_group'), config.orderGroupStatus.PROCESSING.key);
  // }
  
  // async orderGroupReady() {
  //   await this.updateOrderGroupStatus(I18n.t('do_you_want_to_change_status_to_ready'), config.orderGroupStatus.READY.key);
  // }

  // async completeOrderGroup() {
  //   await this.updateOrderGroupStatus(I18n.t('do_you_want_to_complete_order_group'), config.orderGroupStatus.COMPLETED.key);
  // }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => {
                this.componentDidMount(false);
              }}
            />
          }
        >

          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={() => {
                this.componentDidMount(false);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={{ width: '100%' }}>
              
              {(isWideMode() && this.state.isModalVisible) ? (
                <AlertPopUpView
                  isVisible={isWideMode() && this.state.isModalVisible}
                  icon={this.state.modal.icon}
                  title={this.state.modal.title}
                  text={this.state.modal.text}
                  okCallback={this.state.modal.okCallback}
                  okText={this.state.modal.okText}
                  cancelText={this.state.modal.cancelText}
                  cancelCallback={this.state.modal.cancelCallback}
                ></AlertPopUpView>
              ) : (
                <OrderGroupDetailsCard
                  id={this.state.orderGroup._id}
                  history={this.props.history}
                  user={this.state.orderGroup?.user}
                  paymentType={this.state?.orderGroup?.paymentType}
                  paymentStatus={this.state?.orderGroup?.paymentStatus}
                  paymentMethod={this.state?.orderGroup?.paymentMethod}
                  payrexxStatus={this.state.orderGroup.payrexxStatus}
                  status={this.state.orderGroup.status}
                  barName={(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? null : this.state.orderGroup?.barName}
                  tableNumber={this.state.orderGroup?.tableNumber}
                  createdAt={this.state.orderGroup?.createdAt ? moment(this.state.orderGroup?.createdAt).format(I18n.t('OPTIONS.timestampFormat')) : ''}
                  orderDetails={this.state.orderGroup?.orderDetails}
                  cancelledOrderDetails={this.state.orderGroup?.cancelledOrderDetails}
                  grandTotal={this.state.orderGroup.grandTotal}
                  cancelledTotal={this.state.orderGroup.cancelledTotal}
                  currencyCode={this.state.orderGroup.currencyCode}
                  role={this.state.role}
                  onCancelOrder={() => this.cancelOrderGroup()}
                  payingNow={this.state.payingNow}
                  onPayNow={async (payment_type=config.paymentType.ONLINE.key) => {
                    this.setState({
                      payingNow: true
                    });
                    await this.payNow(payment_type)
                    this.setState({
                      payingNow: false
                    });
                  }}
                />
              )}
              
            </View>
          </View>

          {(!isWideMode() && this.state.isModalVisible) ? (
            <AlertPopUpModal
              isVisible={!isWideMode() && this.state.isModalVisible}
              icon={this.state.modal.icon}
              title={this.state.modal.title}
              text={this.state.modal.text}
              okCallback={this.state.modal.okCallback}
              okText={this.state.modal.okText}
              cancelText={this.state.modal.cancelText}
              cancelCallback={this.state.modal.cancelCallback}
            ></AlertPopUpModal>
          ) : null}

        </ScrollView>
        {this.showFooter ? (
          <View>
            <BottomNavigationBar {...this.props} activeTab="OrderGroupDetails" />
          </View>
        ) : null}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});

export default OrderGroupDetails;
