import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '../config';
import { getFcmToken } from '../utils/pushNotificationHelper';
import commonService from './common';

const server = commonService.getAxiosInstance();

const login = async (username, password) => {
    try {
        const headers = await commonService.getRequestHeaders();
        let fcmToken = await getFcmToken();
        let payload = {
            username,
            password,
            notificationToken: fcmToken
        }
        const response = await server.post(`/login`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const loginAsGuest = async (payload={}) => {
    try {
        const headers = await commonService.getRequestHeaders();
        let fcmToken = await getFcmToken();
        payload.notificationToken = fcmToken;
        const response = await server.post(`/login/guest`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const register = async (payload) => {
    try {
        const headers = await commonService.getRequestHeaders();
        let fcmToken = await getFcmToken();
        payload.notificationToken = fcmToken;
        const response = await server.post(`/register`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const loginWithPhoneNumber = async (payload) => {
    try {
        const headers = await commonService.getRequestHeaders();
        let fcmToken = await getFcmToken();
        payload.notificationToken = fcmToken;
        const response = await server.post(`/login/phone`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const verifyLoginWithPhoneNumber = async (payload) => {
    try {
        const headers = await commonService.getRequestHeaders();
        const response = await server.post(`/verify/phone`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const loginWithEmail = async (payload) => {
    try {
        const headers = await commonService.getRequestHeaders();
        let fcmToken = await getFcmToken();
        payload.notificationToken = fcmToken;
        const response = await server.post(`/login/email`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const verifyLoginWithEmail = async (payload) => {
    try {
        const headers = await commonService.getRequestHeaders();
        const response = await server.post(`/verify/email`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const verifyToken = async () => {
    try {
        const headers = await commonService.getRequestHeaders();
        const response = await server.post(`/verify_token`, {}, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const logout = async () => {
    try {
        let response;
        try {
            const headers = await commonService.getRequestHeaders();
            let fcmToken = await getFcmToken();
            response = await server.post(`/logout`, {
                notificationToken: fcmToken
            }, {
                headers
            });
        } catch (error) { }

        try {
            await AsyncStorage.multiRemove([config.tokenKey, config.roleKey, config.roleValueKey, config.barsKey, config.fcmTokenKey, config.hiddenBarsKey]);
        } catch (error) { }
        
        return response?.data;
    } catch (error) {
        return error.response.data;
    }
}

const logoutIfUnauthorized = async (error) => {
    if (error?.response?.status === 401) {
        await logout();
    }
}

const getAppVersion = async () => {
    try {
        const headers = await commonService.getRequestHeaders();
        const response = await server.get(`/app_version`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const deleteAccount = async () => {
    try {
        const headers = await commonService.getRequestHeaders();
        const response = await server.delete(`/profile`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}


export default {
    login,
    loginAsGuest,
    register,
    loginWithPhoneNumber,
    verifyLoginWithPhoneNumber,
    loginWithEmail,
    verifyLoginWithEmail,
    verifyToken,
    logout,
    logoutIfUnauthorized,
    getAppVersion,
    deleteAccount
}