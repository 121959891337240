import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import config from "../config";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import BottomNavigationBar from "../components/BottomNavigationBar";
import I18n from "../utils/i18n";
import cartService from "../services/cart";
import Bars from "./Bars";
import Consumptions from "./Consumptions";
import { useIsFocused } from '@react-navigation/native';
import AppVersionCheck from "../components/AppVersionCheck";

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.barId = this.props?.route?.params?.barId || this.props?.barId;
    this.tableNumber = this.props?.route?.params?.tableNumber || this.props?.tableNumber;

    this.state = {
      role: config.roles.USER.key,
      cartExists: false,
      cartBarId: null
    }
  }

  async componentDidMount() {
    const role = await AsyncStorage.getItem(config.roleKey);
    const token = await AsyncStorage.getItem(config.tokenKey);
    await this.setState({ role: role, token: token });

    if (this.barId && this.tableNumber) {
      let cart = await cartService.get_local_cart();
      if (cart?.[0]?.bar != this.barId) {
        await cartService.delete_local_cart();
      }
      this.props.navigation.navigate('Consumptions', { barId: this.barId, tableNumber: this.tableNumber });
      return;
    }

    if (role === config.roles.USER.key) {
      this.userRedirectToBarsIfCartExists();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const role = await AsyncStorage.getItem('role');
    if (role === config.roles.USER.key) {
      if (prevProps.isFocused !== this.props.isFocused) {
        if (this.props.isFocused) {
          this.userRedirectToBarsIfCartExists();
        }
      }
    }
  }

  userRedirectToBarsIfCartExists = async () => {
    let cart = await cartService.get_local_cart();
    if(!cart) {
      await this.setState({ cartExists: false, cartBarId: null });
      return;
    }

    if (cart?.error) {
      await this.setState({ cartExists: false, cartBarId: null });
    } else {
      if (cart?.[0]?.bar) {
        await this.setState({ cartExists: true, cartBarId: cart?.[0]?.bar });
      } else {
        await this.setState({ cartExists: false, cartBarId: null });
      }
    }
  }

  render() {

    if (!this.state.token) {
      return (
        <>
          <Bars {...this.props} activeTab="Home" />
        </>
        
      );
    }

    if (this.state.role === config.roles.USER.key) {
      if(this.state.cartExists) {
        return (
          <>
            <Consumptions {...this.props} activeTab="Home" barId={this.state.cartBarId} tableNumber={this.tableNumber} autoRedirected={true} />
          </>
        )
      } else {
        return (
          <>
            <Bars {...this.props} activeTab="Home" tableNumber={this.tableNumber} />
          </>
        );
      }
    } else if (this.state.role === config.roles.CASHIER.key || this.state.role === config.roles.SERVER.key) {
      return (
        <>
          <Consumptions {...this.props} activeTab="Home" tableNumber={this.tableNumber} />
        </>
      );
    } else {
      return (
        <SafeAreaView style={styles.container}>
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
          >

            <View style={{ alignItems: 'center' }}>
              <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                {I18n.t('not_yet_implemented')}
              </Text>
            </View>
          </ScrollView>
          <View>
            <BottomNavigationBar {...this.props} activeTab="Home" role={this.state.role} />
          </View>
        </SafeAreaView>
      )
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});


// Wrap and export
export default function(props) {
  const isFocused = useIsFocused();

  return (
    <>
      <Home {...props} isFocused={isFocused} />
      <AppVersionCheck />
    </>
  );
}

// export default Home;