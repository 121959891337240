import React from "react";
import { StyleSheet, View, Text, SafeAreaView, ScrollView, StatusBar } from "react-native";

class CustomChip extends React.Component {
  render() {
    return (
        <View
            style={{
                ...styles.chip,
                backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : styles.chip.backgroundColor,
                borderColor: this.props.borderColor ? this.props.borderColor : styles.chip.borderColor,
            }}
        >
            <Text
                style={{
                    ...styles.chipText,
                    color: this.props.color ? this.props.color : styles.chipText.color,
                }}>{this.props.text}</Text>
        </View>
    );
  }
}

export default CustomChip;

const styles = StyleSheet.create({
    chip: {
        backgroundColor: '#e1e1e1',
        borderRadius: 8,
        padding: 4,
        paddingLeft: 7,
        paddingRight: 7,
        marginBottom: 5,
        borderWidth: 2,
        borderColor: 'transparent',
        justifyContent: 'center'
    },
    chipText: {
        fontSize: 15,
        fontWeight: '700',
        color: 'black'
    }
});