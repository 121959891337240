import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, StatusBar, RefreshControl, Image, Platform, TouchableOpacity} from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import BottomNavigationBar from '../components/BottomNavigationBar';
import orderGroupService from '../services/orderGroups';
import payrexxService from '../services/payrexx';
import OrderGroupCard from '../components/OrderGroupCard';
import I18n from '../utils/i18n';
import config from '../config';
import OrdersIcon from './../assets/icons/orders.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import KeepAwake from 'react-native-keep-awake';
import RefreshButton from '../components/RefreshButton';
import commonStyles from './../styles/common';
import { isWideMode } from '../utils/common';
import LoadMoreButton from '../components/LoadMoreButton';
import userService from './../services/user';
import OrderGroupDetails from './OrderGroupDetails';
import ScanTableQrCode from '../components/ScanTableQrCode';
import NotificationCheck from '../components/NotificationError';

class OrderGroups extends React.Component {
  constructor(props) {
    super(props);

    this.orderGroupId = this.props?.route?.params?.orderGroupId || this.props?.orderGroupId;
    this.payrexxId = this.props?.route?.params?.payrexxId || this.props?.payrexxId;
    this.payrexxReferenceId = this.props?.route?.params?.payrexxReferenceId || this.props?.payrexxReferenceId;
    this.history = this.props?.route?.params?.history || this.props?.history;

    this.timer = null;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      bars: [],
      orderGroups: [],
      totalOrderGroupsCount: 0,
      loading: false,
      isModalVisible: false,
      limit: config.orderLimit,
      skip: 0,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      status: null,
      role: null,
      selectedOrderGroupId: null,
      quickOrderFilter: false,
      activeOrderGroupsTab: config.orderGroupPaymentStatus.PENDING.key,
    }
  }

  async componentDidMount(show_loading = true) {

    let current_role = await AsyncStorage.getItem(config.roleKey);
    this.setState({ loading: true, isModalVisible: show_loading && true, modal: this.loading_modal_props, role: current_role });

    this.orderGroupId = this.props?.route?.params?.orderGroupId;
    this.payrexxId = this.props?.route?.params?.payrexxId;
    this.payrexxReferenceId = this.props?.route?.params?.payrexxReferenceId;

    let isAlwaysOnDisplay = await AsyncStorage.getItem('isAlwaysOnDisplay');
    if (isAlwaysOnDisplay) {
      isAlwaysOnDisplay = JSON.parse(isAlwaysOnDisplay);
      if (isAlwaysOnDisplay) {
        if (Platform.OS != 'web') {
          KeepAwake.activate();
        }

        this.timer = setInterval(() => {
          this.getOrderGroups(false, (Platform.OS == 'ios' ? false : true), this.state.skip + this.state.limit);
        }, 1000 * config.orderGroupRefreshInterval);

      } else {
        if (Platform.OS != 'web') {
          KeepAwake.deactivate();
        }
      }
    } else {
      this.timer = setInterval(() => {
        this.getOrderGroups(false, (Platform.OS == 'ios' ? false : true), this.state.skip + this.state.limit);
      }, 1000 * config.orderGroupRefreshIntervalLong);
    }

    this.setState({ loading: false, isModalVisible: show_loading && true });
    
    if (!this.payrexxId) {
      await this.getOrderGroups(show_loading);
    } else {
      this.setState({ loading: true, isModalVisible: true });
      let payrexxRes = await payrexxService.get_gateway(this.payrexxId);

      // remove payrexxId & payrexxReferenceId from URL params
      this.props.navigation.setParams({
        payrexxId: undefined,
        payrexxReferenceId: undefined
      });
      this.payrexxId = null;
      this.payrexxReferenceId = null;

      if (payrexxRes.error) {
        this.setState({
          modal: {
            ...this.default_modal_props,
            icon: 'error',
            title: I18n.t('error'),
            text: I18n.t(payrexxRes.error_code),
            okCallback: async () => {
              await this.getOrderGroups(show_loading);
              this.setState({ isModalVisible: false });
            }
          }
        });
        return
      }
      if (payrexxRes.gateway.status == "confirmed" || payrexxRes.gateway.status == "completed") {
        this.setState({
          modal: {
            ...this.default_modal_props,
            icon: 'success',
            title: I18n.t('success'),
            text: I18n.t('payment_successful'),
            okCallback: async () => {
              await this.getOrderGroups(show_loading);
              this.setState({
                loading: false,
                isModalVisible: false
              });
            }
          }
        });
      } else {
        this.setState({
          modal: {
            ...this.default_modal_props,
            icon: 'error',
            title: I18n.t('error'),
            text: I18n.t('payment_failed'),
            okCallback: async () => {
              await this.getOrderGroups(show_loading);
              this.setState({
                loading: false,
                isModalVisible: false
              });
            }
          }
        });
      }
    }

    let profileRes = await userService.get_profile();
    if (profileRes.error) {
      try {
        // await AsyncStorage.clear()
      } catch (e) {
        // clear error
      }
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: 'Login', params: { redirectTo: 'OrderGroups' } }],
      });
      return;
    }

  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  async getOrderGroups(show_loading, show_small_loading=true, limit=config.orderLimit, skip=0, push=false) {


    if (push && this.state.orderGroups.length >= this.state.totalOrderGroupsCount) {
      return
    }

    await this.setState({
      loading: show_small_loading, isModalVisible: show_loading && true, modal: this.loading_modal_props,
      limit: limit, skip: skip
    });

    let payload = {
      limit: limit,
      skip: skip
    }

    if ((this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) && this.state.activeOrderGroupsTab) {
      payload.paymentStatus = this.state.activeOrderGroupsTab;
    }

    let orderGroupsRes;
    if (this.history)
      orderGroupsRes = await orderGroupService.get_all_order_groups(payload);
    else
      orderGroupsRes = await orderGroupService.get_order_groups(payload);

    if(orderGroupsRes.error) {
      this.setState({
        loading: false,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: orderGroupsRes.error
        }
      });
      return
    }

    let orderGroups = orderGroupsRes.orderGroups;
    let totalOrderGroupsCount = orderGroupsRes.count ? orderGroupsRes.count : 0;
    if (push) {
      orderGroups = [...this.state.orderGroups, ...orderGroups];
    }

    let selectedOrderGroupId = this.state.selectedOrderGroupId;

    // if selectedOrderGroup is not in orderGroups, reset selectedOrderGroup
    if (this.state.selectedOrderGroupId && !orderGroups.find(orderGroup => orderGroup._id == this.state.selectedOrderGroupId)) {
      selectedOrderGroupId = null
    }

    this.setState({
      orderGroups: orderGroups,
      totalOrderGroupsCount: totalOrderGroupsCount,
      isModalVisible: show_loading && false,
      loading: false,
      selectedOrderGroupId: selectedOrderGroupId
    });
  }

  async filterResults(activeStatuses) {
    await this.setState({ status: activeStatuses.join(",") });
    await this.getOrderGroups(false);
  }

  async payForOrderAtTable(params) {

    // show loading modal
    this.setState({
      modal: {
        ...this.default_modal_props,
        icon: 'loading',
        title: I18n.t('loading'),
        text: I18n.t('please_wait')
      },
      isModalVisible: true
    });

    let existingOrderAtTable = await orderGroupService.get_order_groups({
      barId: params.barId,
      tableNumber: params.tableNumber,
      paymentStatus: config.orderGroupPaymentStatus.PENDING.key
    });

    if(existingOrderAtTable.error) {
      this.setState({
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: existingOrderAtTable.error,
          okCallback: async () => {
            this.setState({ isModalVisible: false });
          }
        },
        isModalVisible: true
      });
      return
    }

    if(existingOrderAtTable.orderGroups.length == 0) {
      // Error, no order found to pay
      this.setState({
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t('no_order_found'),
          okCallback: async () => {
            this.setState({ isModalVisible: false });
          }
        },
        isModalVisible: true
      });
      return
    }

    if(existingOrderAtTable.orderGroups.length == 1) {
      // Close the loader
      this.setState({ isModalVisible: false });
      // Only one order found, pay it
      let orderGroup = existingOrderAtTable.orderGroups[0];
      if (isWideMode()) {
        await this.setState({
          selectedOrderGroupId: orderGroup._id
        })
      } else {
        this.props.navigation.navigate('OrderGroupDetails', { orderGroupId: orderGroup._id });
      }
      return
    }

    // Multiple orders found, show error unable to pay at the moment
    this.setState({
      modal: {
        ...this.default_modal_props,
        icon: 'error',
        title: I18n.t('error'),
        text: I18n.t('unable_to_pay_at_the_moment'),
        okCallback: async () => {
          this.setState({ isModalVisible: false });
        }
      },
      isModalVisible: true
    });

  }

  render() {

    return (
      <SafeAreaView style={styles.container}>

        <View>
          <NotificationCheck />
        </View>

        <View style={{width: '100%', height: '100%', flex: 1, flexDirection: 'row'}}>
        <View style={isWideMode() ? commonStyles.leftSideView : commonStyles.centeredView}>
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => {
                this.getOrderGroups(false);
              }}
            />
          }
          paginationEnabled={true}
          onEndReachedThreshold={0.5}
          onMomentumScrollEnd={(event) => {
            const isAtAnd = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) === parseInt(event.nativeEvent.contentSize.height);
            if (isAtAnd) {
              this.getOrderGroups(false, true, this.state.limit, this.state.skip + this.state.limit, true);
            }
          }}
          onScrollEndDrag={(event) => {
            const isAtAnd = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) === parseInt(event.nativeEvent.contentSize.height);
            if (isAtAnd) {
              this.getOrderGroups(false, true, this.state.limit, this.state.skip + this.state.limit, true);
            }
          }}
        >

          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={() => {
                this.getOrderGroups(false);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          {(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? (
            <View style={{flexDirection: 'row', padding: 10}}>
              <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                {/* Waiting */}
                {/* <TouchableOpacity
                  style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.WAITING.key) ? {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: config.primaryColor, borderWidth: 2}}
                  onPress={async () => {
                    if (this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.WAITING.key) {
                      await this.setState({
                        activeOrderGroupsTab: null
                      })
                    } else {
                      await this.setState({
                        activeOrderGroupsTab: config.orderGroupPaymentStatus.WAITING.key
                      })
                    }
                    this.getOrderGroups(true)
                  }}
                >
                  <Text style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.WAITING.key) ? {color: 'white', fontSize: 20} : {color: config.primaryColor, fontSize: 20}}>{config.orderGroupPaymentStatus.WAITING.name}</Text>
                </TouchableOpacity> */}
                {/* Pending */}
                <TouchableOpacity
                  style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.PENDING.key) ? {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: config.primaryColor, borderWidth: 2}}
                  onPress={async () => {
                    if (this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.PENDING.key) {
                      await this.setState({
                        activeOrderGroupsTab: null
                      })
                    } else {
                      await this.setState({
                        activeOrderGroupsTab: config.orderGroupPaymentStatus.PENDING.key
                      })
                    }
                    this.getOrderGroups(true)
                  }}
                >
                  <Text style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.PENDING.key) ? {color: 'white', fontSize: 20} : {color: config.primaryColor, fontSize: 20}}>{config.orderGroupPaymentStatus.PENDING.name}</Text>
                </TouchableOpacity>
                {/* Completed */}
                <TouchableOpacity
                  style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.COMPLETED.key) ? {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: config.primaryColor, borderWidth: 2}}
                  onPress={async () => {
                    if (this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.COMPLETED.key) {
                      await this.setState({
                        activeOrderGroupsTab: null
                      })
                    } else {
                      await this.setState({
                        activeOrderGroupsTab: config.orderGroupPaymentStatus.COMPLETED.key
                      })
                    }
                    this.getOrderGroups(true)
                  }}
                >
                  <Text style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.COMPLETED.key) ? {color: 'white', fontSize: 20} : {color: config.primaryColor, fontSize: 20}}>{config.orderGroupPaymentStatus.COMPLETED.name}</Text>
                </TouchableOpacity>
                {/* Failed */}
                <TouchableOpacity
                  style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.FAILED.key) ? {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: config.primaryColor, borderWidth: 2}}
                  onPress={async () => {
                    if (this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.FAILED.key) {
                      await this.setState({
                        activeOrderGroupsTab: null
                      })
                    } else {
                      await this.setState({
                        activeOrderGroupsTab: config.orderGroupPaymentStatus.FAILED.key
                      })
                    }
                    this.getOrderGroups(true)
                  }}
                >
                  <Text style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.FAILED.key) ? {color: 'white', fontSize: 20} : {color: config.primaryColor, fontSize: 20}}>{config.orderGroupPaymentStatus.FAILED.name}</Text>
                </TouchableOpacity>
                {/* Refunded */}
                <TouchableOpacity
                  style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.REFUNDED.key) ? {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, marginRight: 10} : {backgroundColor: 'white', padding: 10, borderRadius: 5, marginRight: 10, borderColor: config.primaryColor, borderWidth: 2}}
                  onPress={async () => {
                    if (this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.REFUNDED.key) {
                      await this.setState({
                        activeOrderGroupsTab: null
                      })
                    } else {
                      await this.setState({
                        activeOrderGroupsTab: config.orderGroupPaymentStatus.REFUNDED.key
                      })
                    }
                    this.getOrderGroups(true)
                  }}
                >
                  <Text style={(this.state.activeOrderGroupsTab == config.orderGroupPaymentStatus.REFUNDED.key) ? {color: 'white', fontSize: 20} : {color: config.primaryColor, fontSize: 20}}>{config.orderGroupPaymentStatus.REFUNDED.name}</Text>
                </TouchableOpacity>
              </ScrollView>
            </View>
          ) : null}

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={{ width: '100%' }}>

              {this.state.orderGroups.length == 0 ? (
                <View style={{ alignItems: 'center' }}>
                  {(Platform.OS == "web") ? (
                    <Image source={require('./../assets/icons/orders.svg')} style={{ width: 100, height: 100 }} />
                  ) : (
                    <OrdersIcon width={100} height={100} />
                  )}
                  <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                    {I18n.t('no_orders_yet')}
                  </Text>
                </View>
              ) : null}
              
              {this.state.orderGroups.map((orderGroup, index) => {
                return (
                  <OrderGroupCard
                    key={index}
                    id={orderGroup._id}
                    highlighted={this.state.selectedOrderGroupId == orderGroup._id}
                    grandTotal={orderGroup.grandTotal}
                    cancelledTotal={orderGroup.cancelledTotal}
                    currencyCode={orderGroup.currencyCode}
                    paymentType={orderGroup?.payrexxPayment?.brand ? orderGroup?.payrexxPayment?.brand : orderGroup?.paymentType}
                    status={orderGroup.status}
                    paymentStatus={orderGroup.paymentStatus}
                    payrexxStatus={orderGroup.payrexxStatus}
                    barName={(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? null : orderGroup?.barName}
                    tableNumber={(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? orderGroup?.tableNumber : null}
                    beeperNumber={orderGroup?.beeperNo}
                    users={(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? orderGroup?.users : null}
                    history={this.history}
                    createdAt={orderGroup?.createdAt ? moment(orderGroup.createdAt).format(I18n.t('OPTIONS.timestampFormat')) : ''}
                    onClick={async () => {
                      if (isWideMode()) {
                        await this.setState({
                          selectedOrderGroupId: null
                        })
                        await this.setState({
                          selectedOrderGroupId: orderGroup._id
                        })
                      } else {
                        if(this.history)
                          this.props.navigation.navigate('OrderGroupHistoryDetails', { orderGroupId: orderGroup._id, history: true });
                        else
                          this.props.navigation.navigate('OrderGroupDetails', { orderGroupId: orderGroup._id });
                      }
                    }}
                  />
                )
              })}
              
            </View>
          </View>
          
          {(Platform.OS == 'web' && this.state.totalOrderGroupsCount && (this.state.orderGroups.length < this.state.totalOrderGroupsCount)) ? (
            <LoadMoreButton
              onPress={() => {
                this.getOrderGroups(false, true, this.state.limit, this.state.skip + this.state.limit, true);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <AlertPopUpModal
            isVisible={this.state.isModalVisible}
            icon={this.state.modal.icon}
            title={this.state.modal.title}
            text={this.state.modal.text}
            okCallback={this.state.modal.okCallback}
          ></AlertPopUpModal>

        </ScrollView>

        {(this.state.role == config.roles.USER.key) ? (
          <ScanTableQrCode
            {...this.props}
            text={I18n.t('scan_and_pay')}
            onReadCode={async (params) => {
              this.payForOrderAtTable(params);
            }}
          />
        ) : null}

        <View>
          {/* {(this.state?.role == config.roles.CASHIER.key || this.state?.role == config.roles.SERVER.key) ? (
            <OrderStatusSelection onStatusChange={(activeStatuses) => this.filterResults(activeStatuses)} />
          ) : null} */}
          <BottomNavigationBar {...this.props} activeTab="OrderGroups" />
        </View>
        </View>
        
        
        {isWideMode() ? (
        <View style={{...commonStyles.rightSideView}}>
        <View style={{width: '100%', height: '100%'}}>
            {this.state.selectedOrderGroupId ? (
              <OrderGroupDetails
                orderGroupId={this.state.selectedOrderGroupId}
                showFooter={false}
                navigation={this.props.navigation}
                history={this.history}
              />
            ) : (
              <View style={{ alignItems: 'center' }}>
                {(Platform.OS == "web") ? (
                  <Image source={require('./../assets/icons/orders.svg')} style={{ width: 100, height: 100 }} />
                ) : (
                  <OrdersIcon width={100} height={100} />
                )}
                <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                  {I18n.t('no_order_selected')}
                </Text>
              </View>
            )}
        </View>
        </View>
        ) : null}
        
        
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});

export default OrderGroups;
