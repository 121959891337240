export default {
    hello: 'Hello',
    welcome_back: 'Welcome back',
    email_or_username: 'Email or username',
    password: 'Password',
    login: 'Login',
    dont_have_an_account: 'Don\'t have an account?',
    register: 'Register',
    create_an_account: 'Create an account',
    already_have_an_account: 'Already have an account?',
    email: 'Email',
    username: 'Username',
    full_name: 'Full name',
    confirm_password: 'Confirm password',
    passwords_didnt_match: 'Passwords didn\'t match',
    invalid_email: 'Invalid email',
    username_already_exists: 'Username already exists',
    registration_successful: 'Registration successful',
    unknown_error_occurred: 'Unknown error occurred',
    please_login_again: 'Please login again',
    error: 'Error',
    user_not_found: 'User not found',
    ok: 'OK',
    loading: 'Loading',
    please_wait: 'Please wait',
    registered_successfully: 'Registered successfully',
    add: 'Add',
    invalid_token: 'Invalid token',
    home: 'Home',
    cart: 'Cart',
    profile: 'Profile',
    success: 'Success',
    consumptions_added_to_cart: 'Consumptions added to cart',
    unknown_error: 'Unknown error',
    add_to_cart: 'Add to cart',
    total_items: 'Total items',
    total_cost: 'Total cost',
    cart_saved: 'Cart saved',
    cart_is_empty: 'Cart is empty',
    order_placed: 'Order placed',
    orders: 'Orders',
    order_id: 'Order ID',
    pending: 'Pending',
    confirmed: 'In queue',           // 'Confirmed' is changed to 'In queue' for better understanding
    processing: 'Processing',
    ready: 'Ready',
    completed: 'Completed',
    cancelled: 'Cancelled',
    payment_pending: 'Payment pending',
    payment_processing: 'Payment processing',
    payment_failed: 'Payment failed',
    paymentPending: 'Payment pending',
    paymentProcessing: 'Payment processing',
    paymentFailed: 'Payment failed',
    logout: 'Logout',
    no_orders_yet: 'No orders yet',
    no_order_tickets_yet: 'No order tickets yet',
    no_skistations_found: 'No ski stations found',
    no_bars_found: 'No bars found',
    no_consumptions_found: 'No consumptions found',
    go_to_cart: 'Go to cart',
    view_orders: 'View orders',
    not_yet_implemented: 'Not yet implemented',
    confirm_order: 'Confirm order',
    process_order: 'Process order',
    order_ready: 'Order ready',
    complete_order: 'Complete order',
    do_you_want_to_confirm_order: 'Do you want to confirm order?',
    do_you_want_to_process_order: 'Do you want to process order?',
    do_you_want_to_change_status_to_ready: 'Do you want to change status to ready?',
    do_you_want_to_complete_order: 'Do you want to complete order?',
    yes: 'Yes',
    no: 'No',
    change_password: 'Change password',
    old_password: 'Old password',
    new_password: 'New password',
    new_password_can_not_be_same_as_old_password: 'New password can not be same as old password',
    new_password_and_confirm_password_didnt_match: 'New password and confirm password didn\'t match',
    incorrect_old_password: 'Incorrect old password',
    password_updated_successfully: 'Password updated',
    incorrect_password: 'Incorrect password',
    always_on_display: 'Always on display',
    cannot_place_order_from_multiple_ski_stations: 'Cannot place order from multiple ski stations',
    save_cart: 'Save cart',
    place_order: 'Place order',
    continue_to_order: 'Continue to order',
    clear_cart: 'Clear cart',
    warning: 'Warning',
    do_you_want_to_clear_cart: 'Do you want to clear cart?',
    cancel: 'Cancel',
    cart_is_now_empty: 'Cart is now empty',
    order_number: 'Order number',
    cancel_order: 'Cancel order',
    do_you_want_to_cancel_order: 'Do you want to cancel order?',
    do_you_want_to_make_order_pending: 'Do you want to make order pending?',
    payment_successful: 'Payment successful',
    payment_failed: 'Payment failed',
    unable_to_cancel_this_order: 'Unable to cancel this order',
    error_retrieving_transaction: 'Error retrieving transaction',
    transaction_not_found: 'Transaction not found',
    gateway_not_found: 'Gateway not found',
    order_cancelled_successfully: 'Order cancelled successfully',
    order_not_found: 'Order not found',
    order_already_cancelled: 'Order already cancelled',
    transaction_not_found: 'Transaction not found',
    order_cancelled_but_an_error_occured_during_refund: 'Order cancelled but an error occured during refund',
    order_cancelled_but_unable_to_make_refund: 'Order cancelled but unable to make refund',
    pay_now: 'Pay now',
    do_you_want_to_pay_now: 'Do you want to pay now?',
    cancel_request: 'Cancel request',
    unable_to_pay_now: 'Unable to pay now',
    unable_to_pay_for_this_order: 'Unable to pay for this order',
    are_you_sure_you_want_to_change_bar_status: 'Are you sure you want to change bar status?',
    order_history: 'Order history',
    this_app_version_is_not_supported: 'This app version is not supported',
    please_update_your_app: 'Please update your app',
    app_version: 'App version',
    change_language: 'Change language',
    bars: 'Bars',
    consumptions: 'Consumptions',
    order_details: 'Order details',
    order_history_details: 'Order history details',
    payment: 'Payment',
    password_should_be_atleast_6_characters: 'Password should be atleast 6 characters',
    i_forgot_my_password: 'I forgot my password',
    send_mail: 'Send mail',
    closed: 'Closed',
    refresh: 'Refresh',
    refreshing: 'Refreshing',
    load_more: 'Load more',
    step_back: 'Step back',
    others: 'Others',
    some_consumptions_are_not_added_to_cart_do_you_want_to_go_back_without_adding_them: 'Some consumptions are not added to cart. Do you want to go back without adding them?',
    one_or_more_bar_not_currently_open: 'One or more bar not currently open',
    bar_not_currently_open: 'Bar not currently open',
    incorrect_username_or_password: 'Incorrect username or password',
    no_order_selected: 'No order selected',
    go_to_orders: 'Go to orders',
    beeper_number: 'Beeper number',
    paid_by_cash: 'Paid by cash',
    paid_by_card: 'Paid by card',
    order_cancelled_please_refund_the_client: 'Order cancelled. Please refund the client',
    printer_ip: 'Printer IP',
    mark_as_completed: 'Mark as completed',
    in_progress: 'In progress',
    daily_report: 'Daily report',
    actual_turnover: 'Actual turnover',
    expected_turnover: 'Expected turnover',
    generate_report: 'Generate report',
    cash_turnover: 'Cash turnover',
    card_turnover: 'Card turnover',
    password_changed: 'Password changed',
    cash: 'Cash',
    card: 'Card',
    online: 'Online',
    quick_order: 'Quick order',
    delete_my_account_and_erase_data: 'Delete my account and erase data',
    are_you_sure: 'Are you sure?',
    do_you_want_to_delete_account_and_erase_data_this_operation_is_irreversible_and_takes_30_days_for_completion: 'Do you want to delete account and erase data? This operation is irreversible and takes 30 days for completion',
    delete_account: 'Delete account',
    keep_account: 'Keep account',
    something_went_wrong: 'Something went wrong',
    email_already_exists: 'Email already exists',
    order_groups: 'Order groups',
    order_group: 'Order group',
    order_group_id: 'Order group ID',
    order_group_details: 'Order group details',
    no_order_groups_yet: 'No order groups yet',
    id: 'ID',
    network_error: 'Network error',
    table: 'Table',
    or: 'or',
    continue_with_phone_number: 'Continue with phone number',
    continue_with_email: 'Continue with email',
    phone_number: 'Phone number',
    invalid_phone_number: 'Invalid phone number',
    invalid_otp: 'Invalid OTP',
    invalid_code: 'Invalid code',
    send_otp: 'Send OTP',
    send_code: 'Send code',
    otp: 'OTP',
    code: 'Code',
    otp_sent_to: 'OTP sent to',
    code_sent_to: 'Code sent to',
    resend_otp_in: 'Resend OTP in',
    resend_code_in: 'Resend code in',
    seconds: 'seconds',
    resend_otp: 'Resend OTP',
    resend_code: 'Resend code',
    validate: 'Validate',
    edit: 'Edit',
    otp_should_be_3_characters: 'OTP should be 3 characters',
    code_should_be_3_characters: 'Code should be 3 characters',
    bar_id_is_required: 'Bar ID is required',
    failed: 'Failed',
    online_payment: 'Online payment',
    refunded: 'Refunded',
    do_you_want_to_pay_by_cash: 'Do you want to pay by cash?',
    do_you_want_to_pay_by_card: 'Do you want to pay by card?',
    do_you_want_to_receive_payment_online: 'Do you want to receive payment online?',
    do_you_want_to_receive_payment_by_cash: 'Do you want to receive payment by cash?',
    do_you_want_to_receive_payment_by_card: 'Do you want to receive payment by card?',
    do_you_want_to_cancel_all_orders: 'Do you want to cancel all orders?',
    order_group_cancelled_and_refunded_successfully: 'Order cancelled and refunded successfully',
    order_tickets: 'Order tickets',
    order_ticket_details: 'Order ticket details',
    order_group_cancelled_please_refund_the_client: 'Order cancelled. Please refund the client',
    kot: 'KOT',
    table_number: 'Table number',
    scan_qr_code: 'Scan QR code',
    order_ticket_history: 'Order ticket history',
    order_ticket_history_details: 'Order ticket history details',
    update_cart: 'Update cart',
    save_to_cart: 'Save to cart',
    go_to_cart_and_clear_to_order_from_another_bar: 'Go to cart and clear to order from another bar',
    country_code: 'Country code',
    invalid_country_code: 'Invalid country code',
    scan_and_order: 'Scan & order',
    scan_and_pay: 'Scan & pay',
    no_order_found: 'No order found',
    unable_to_pay_at_the_moment: 'Unable to pay at the moment',
    you_can_only_add_items_from_one_bar_at_a_time: 'You can only add items from one bar at a time',
    currently_not_available: 'Currently not available',
    available_from: 'Available from',
    available_to: 'Available to',
    to: 'to',
    available_between: 'Available between',
    and: 'and',
    waiting: 'Waiting',
    paid: 'Paid',
    waiting_for_payment: 'Waiting for payment',
    payment_failed: 'Payment failed',
    to_be_paid: 'To be paid',
    app_version_missing: 'App version missing',
    app_version_too_old: 'App version too old',
    reload: 'Reload',
    bar_is_closed_permanantly: 'Bar is closed permanantly',
    beeper: 'Beeper',
    invalid_fullname: 'Invalid fullname',
    unauthorized: 'Unauthorized',
    report_generated: 'Report generated',
    continue: 'Continue',
    guest_account: 'Guest account',
    all_saved_data_will_be_lost_upon_logout: 'All saved data will be lost upon logout',
    do_you_want_to_logout: 'Do you want to logout?',
    continue_without_an_account: 'Continue without an account',
    please_enable_notification_permission_to_get_updates_on_your_orders: 'Please enable notification permission to get updates on your orders',
    new: 'New',
    please_close_this_tab: 'Please close this tab',
    payment_successful: 'Payment successful',
    payment_failed: 'Payment failed',
    payment_cancelled: 'Payment cancelled',
    order_group_already_checked_out: 'Order already checked out',
    app_version_too_old: 'App version too old',
    unable_to_save_hidden_bar_locally: 'Unable to save hidden bar locally',
    unable_to_remove_hidden_bar_locally: 'Unable to remove hidden bar locally',
    unable_to_get_local_hidden_bars: 'Unable to get local hidden bars',
    added_from_scanned_qr: 'Added from scanned QR',
    failed_to_create_payment_gateway: 'Failed to create payment gateway',
    order_updates: 'Order updates',
    country_list: {
        switzerland: 'Switzerland',
        germany: 'Germany',
        france: 'France',
        italy: 'Italy',
        united_kingdom: 'United Kingdom',
        united_states: 'United States',
        india: 'India'
    },
    SENTENCE: {
        forgot_password_text: 'Please send us a mail to info@jm-contactless.ch with your contact information and we will help you recover your account',
    },
    OPTIONS: {
        timestampFormat: 'DD/MM/YYYY HH:mm',
        timeFormat: 'HH:mm',
    },
    LOCATION_ERROR: {
        PERMISSION_DENIED: 'Location permission denied',
        PERMISSION_DENIED_INFO: 'Please enable location permission to get bars near you',
        PERMISSION_DENIED_SECONDARY_INFO: 'Click to enable',
        POSITION_UNAVAILABLE: 'Position unavailable',
        PERMISSION_UNAVAILABLE_INFO: 'Please reload the page to retry',
        TIMEOUT: 'Timeout',
        UNKNOWN_ERROR: 'Unknown error'
    },
};