import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, StatusBar, RefreshControl, Platform} from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import BottomNavigationBar from '../components/BottomNavigationBar';
import config from '../config';
import orderService from '../services/orders';
import I18n from '../utils/i18n';
import OrderDetailsCard from '../components/OrderDetailsCard';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RefreshButton from '../components/RefreshButton';
import KeepAwake from 'react-native-keep-awake';
import { isWideMode } from '../utils/common';
import AlertPopUpView from '../components/AlertPopUpView';
import { openLink } from '../utils/browser';

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.orderId =  this.props?.route?.params?.orderId || this.props?.orderId;
    this.showFooter = (this.props?.route?.params?.showFooter == false || this.props?.showFooter == false) ? false : true;
    this.history = this.props?.route?.params?.history;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      order: {},
      payingNow: false,
      isModalVisible: false,
      history: this.props?.route?.params?.history,
      loading: false,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      role: config.roles.USER.key,
      order_id: this.orderId
    }
  }

  // async componentDidUpdate(nextProps, nextState) {
  //   nextState = {
  //       ...nextState,
  //       ...nextProps
  //   }

  //   if (nextState.order?._id && nextState.order?._id != this.state.order_id) {
  //     // await this.setState({order_id: nextState.order?._id});
  //     // this.componentDidMount(false);
  //   }
  // }

  async componentDidMount(show_loading = true) {

    this.setState({history: this.props?.route?.params?.history})

    let current_role = await AsyncStorage.getItem(config.roleKey);
    
    this.setState({
      role: current_role
    });

    this.getOrder(show_loading);


    let isAlwaysOnDisplay = await AsyncStorage.getItem('isAlwaysOnDisplay');
    if (isAlwaysOnDisplay) {
      isAlwaysOnDisplay = JSON.parse(isAlwaysOnDisplay);
      if (isAlwaysOnDisplay) {
        if (Platform.OS != 'web') {
          KeepAwake.activate();
        }

        this.timer = setInterval(() => {
          if (!this.state.isModalVisible)
            this.getOrder(false, (Platform.OS == 'ios' ? false : true));
        }, 1000 * config.orderRefreshInterval);

      } else {
        if (Platform.OS != 'web') {
          KeepAwake.deactivate();
        }
      }
    } else {
      this.timer = setInterval(() => {
        if (!this.state.isModalVisible)
          this.getOrder(false, (Platform.OS == 'ios' ? false : true));
      }, 1000 * config.orderRefreshIntervalLong);
    }

  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  async getOrder(show_loading=true, show_small_loading=true) {
    this.setState({
      loading: show_small_loading,
      isModalVisible: show_loading && true,
      modal: this.loading_modal_props
    });

    let orderRes = await orderService.get_order(this.state.order_id);
    
    if(orderRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(orderRes.error_code),
        }
      })
      this.props.navigation.navigate('Orders');
      return;
    }

    this.setState({
      order: orderRes.order,
      loading: false,
      isModalVisible: show_loading && false,
    });
  }

  async conf(msg) {
    return new Promise((resolve, reject) => {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'info',
          title: msg,
          okCallback: () => {
            resolve(true);
            this.setState({
              isModalVisible: false,
            });
          },
          okText: I18n.t('yes'),
          cancelCallback: () => {
            resolve(false);
            this.setState({
              isModalVisible: false
            })
          },
          cancelText: I18n.t('no')
        }
      });
    });
  }

  async updateOrderStatus(msg, status) {
    let ok = await this.conf(msg);
    if (!ok) return;

    this.setState({
      isModalVisible: true,
      modal: this.loading_modal_props
    });

    let updateOrderRes = await orderService.update_order(this.orderId, {status: status});

    if(updateOrderRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(updateOrderRes.error_code),
        }
      })
      return;
    }

    if (this.props?.onOrderStatusChange) {
      this.props.onOrderStatusChange();
    }

    this.setState({
      isModalVisible: false,
      modal: this.default_modal_props
    });

    this.componentDidMount(false);
  }

  async payNow() {
    let ok = await this.conf(I18n.t('do_you_want_to_pay_now'));
    if (!ok) return;

    this.setState({
      // isModalVisible: true,
      // modal: this.loading_modal_props
    });

    let payNowRes = await orderService.pay_now(this.orderId);

    if(payNowRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(payNowRes.error_code),
          okCallback: () => {
            this.componentDidMount(false);
            this.setState({
              isModalVisible: false,
            });
          }
        }
      })
      return;
    }

    if (payNowRes.payrexxLink) {

      this.setState({
        loading: false,
        // isModalVisible: false
      });

      let browser_res = await openLink(payNowRes.payrexxLink, 'orderGroup', 'paymentStatus', payNowRes?.orderGroup?._id);
      if (browser_res.error) {
        this.props.navigation.navigate('Payment', {
          payrexxLink: payNowRes.payrexxLink,
          orderId: payNowRes?.order?._id,
          orderGroupId: payNowRes?.orderGroup?._id,
          payrexxId: payNowRes?.order?.payrexxId,
          payrexxReferenceId: payNowRes?.order?.payrexxReferenceId,
        });
      } else {
        this.props.navigation.reset({
          index: 0,
          routes: [{
            name: 'OrderGroups',
            params: { orderId: payNowRes?.order?._id, orderGroupId: payNowRes?.orderGroup?._id, payrexxId: payNowRes?.order?.payrexxId, payrexxReferenceId: payNowRes?.order?.payrexxReferenceId } 
          }],
        });
      }

    } else {
      this.setState({
        loading: false,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'warning',
          title: I18n.t('warning'),
          text: I18n.t('unable_to_pay_now'),
          okText: I18n.t('ok'),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
            this.componentDidMount(false);
          }
        }
      });
    }
  }

  async cancelOrder() {

    let ok = await this.conf(I18n.t('do_you_want_to_cancel_order'));
    if (!ok) return;

    this.setState({
      isModalVisible: true,
      modal: this.loading_modal_props
    });

    let cancelOrderRes = await orderService.cancel_order(this.orderId);
    
    if(cancelOrderRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(cancelOrderRes.error_code),
          okCallback: () => {
            this.componentDidMount(false);
            this.setState({
              isModalVisible: false,
            });
          }
        }
      })
      return;
    }

    if (cancelOrderRes.message) {
      this.setState({
        loading: false,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'success',
          title: I18n.t('success'),
          text: I18n.t(cancelOrderRes.message_code),
          okText: I18n.t('ok'),
          okCallback: () => {
            this.componentDidMount(false);
            this.setState({
              isModalVisible: false
            });
          }
        }
      });
    } else {
      this.componentDidMount(false);
      this.setState({
        isModalVisible: false,
        modal: this.default_modal_props
      });
    }
  }

  async orderPending() {
    await this.updateOrderStatus(I18n.t('do_you_want_to_make_order_pending'), config.orderStatus.PENDING.key);
  }

  async confirmOrder() {
    await this.updateOrderStatus(I18n.t('do_you_want_to_confirm_order'), config.orderStatus.CONFIRMED.key);
  }
  
  async processOrder() {
    await this.updateOrderStatus(I18n.t('do_you_want_to_process_order'), config.orderStatus.PROCESSING.key);
  }
  
  async orderReady() {
    await this.updateOrderStatus(I18n.t('do_you_want_to_change_status_to_ready'), config.orderStatus.READY.key);
  }

  async completeOrder() {
    await this.updateOrderStatus(I18n.t('do_you_want_to_complete_order'), config.orderStatus.COMPLETED.key);
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => {
                this.componentDidMount(false);
              }}
            />
          }
        >

          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={() => {
                this.componentDidMount(false);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          {(isWideMode() && this.state.isModalVisible) ? (
            <AlertPopUpView
              isVisible={isWideMode() && this.state.isModalVisible}
              icon={this.state.modal.icon}
              title={this.state.modal.title}
              text={this.state.modal.text}
              okCallback={this.state.modal.okCallback}
              okText={this.state.modal.okText}
              cancelText={this.state.modal.cancelText}
              cancelCallback={this.state.modal.cancelCallback}
              style={{marginVertical: 30, marginHorizontal: 30}}
            ></AlertPopUpView>
          ) : null}

          <OrderDetailsCard
            id={this.state.order._id}
            history={this.props.history}
            orderNumber={this.state.order.shortOrderNumber}
            beeperNumber={this.state.order.beeperNo}
            user={this.state.order?.userId}
            paymentType={this.state?.order?.payrexxPayment?.brand ? this.state?.order?.payrexxPayment?.brand : this.state?.order?.paymentType}
            status={this.state.order.status}
            payrexxStatus={this.state.order.payrexxStatus}
            secondary_title={this.state.order?.barId?.name}
            description={this.state.order?.createdAt ? moment(this.state.order?.createdAt).format(I18n.t('OPTIONS.timestampFormat')) : ''}
            consumptions={this.state.order.consumptions}
            total={this.state.order.total}
            currencyCode={this.state.order.currencyCode}
            role={this.state.role}
            onOrderPending={() => this.orderPending()}
            onConfirmOrder={() => this.confirmOrder()}
            onProcessOrder={() => this.processOrder()}
            onOrderReady={() => this.orderReady()}
            onCompleteOrder={() => this.completeOrder()}
            onCancelOrder={() => this.cancelOrder()}
            onPayNow={async () => {
              this.setState({
                payingNow: true
              });
              await this.payNow()
              this.setState({
                payingNow: false
              });
            }}
          />

          {(!isWideMode() && this.state.isModalVisible) ? (
            <AlertPopUpModal
              isVisible={!isWideMode() && this.state.isModalVisible}
              icon={this.state.modal.icon}
              title={this.state.modal.title}
              text={this.state.modal.text}
              okCallback={this.state.modal.okCallback}
              okText={this.state.modal.okText}
              cancelText={this.state.modal.cancelText}
              cancelCallback={this.state.modal.cancelCallback}
            ></AlertPopUpModal>
          ) : null}

        </ScrollView>
        {this.showFooter ? (
          <View>
            <BottomNavigationBar {...this.props} activeTab="OrderDetails" />
          </View>
        ) : null}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});

export default OrderDetails;
